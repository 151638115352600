.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-copyright-text {
  text-align: center;
  /* color: #868e96; */
  /* font-weight: bold; */
  font-family: "Google Sans Regular";
}

.no-link {
  pointer-events: none;
  cursor: text;
  text-decoration: none;
  color: #868e96;
}

.footer-div {
  margin-top: 2rem;
}
